// Generated by Framer (7aa0232)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useActiveVariantCallback, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const enabledGestures = {k8UcUQKux: {hover: true}};

const cycleOrder = ["k8UcUQKux"];

const variantClassNames = {k8UcUQKux: "framer-v-1eodogi"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, tap2, width, ...props}) => { return {...props, UdJC1_vBf: tap2 ?? props.UdJC1_vBf} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;tap2?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, UdJC1_vBf, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "k8UcUQKux", enabledGestures, transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTapcp8a5g = activeVariantCallback(async (...args) => {
if (UdJC1_vBf) {
const res = await UdJC1_vBf(...args);
if (res === false) return false;
}
})

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-eBClT", classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-1eodogi", className)} data-border data-framer-name={"Variant 1"} data-highlight layoutDependency={layoutDependency} layoutId={"k8UcUQKux"} onTap={onTapcp8a5g} ref={ref} style={{"--border-bottom-width": "1px", "--border-color": "var(--token-68c05b50-ca7b-4173-82aa-ed42aea1a9b4, rgb(51, 51, 48)) /* {\"name\":\"border/default\"} */", "--border-left-width": "1px", "--border-right-width": "1px", "--border-style": "solid", "--border-top-width": "1px", backgroundColor: "var(--token-cd2934a7-4e35-4347-a32c-9650fca4db23, rgba(24, 24, 24, 0.5))", borderBottomLeftRadius: 8, borderBottomRightRadius: 8, borderTopLeftRadius: 8, borderTopRightRadius: 8, ...style}} variants={{"k8UcUQKux-hover": {"--border-color": "var(--token-52eaa3b2-9b53-4c12-9790-fce4171dff3e, rgb(78, 76, 71)) /* {\"name\":\"border/default hover\"} */", backgroundColor: "var(--token-c18e5c55-e670-494b-9afe-b018358f3867, rgb(30, 30, 30))"}}} {...addPropertyOverrides({"k8UcUQKux-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><motion.div className={"framer-fwto7e"} layoutDependency={layoutDependency} layoutId={"hiDyxaPYs"}><motion.div className={"framer-lovqnv"} data-framer-name={"Line"} layoutDependency={layoutDependency} layoutId={"cbaVqhxUS"} style={{backgroundColor: "rgb(239, 230, 210)", rotate: -45}} variants={{"k8UcUQKux-hover": {rotate: 0}}}/><motion.div className={"framer-gk5npz"} data-framer-name={"Line"} layoutDependency={layoutDependency} layoutId={"BWtZjtq2h"} style={{backgroundColor: "rgb(239, 230, 210)", rotate: 45}} variants={{"k8UcUQKux-hover": {rotate: 0}}}/></motion.div></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-eBClT [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-eBClT .framer-1jvsus0 { display: block; }", ".framer-eBClT .framer-1eodogi { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 5px; height: 41px; justify-content: center; overflow: hidden; padding: 0px 0px 0px 0px; position: relative; width: 41px; will-change: var(--framer-will-change-override, transform); }", ".framer-eBClT .framer-fwto7e { flex: none; height: 13px; overflow: visible; position: relative; width: 20px; }", ".framer-eBClT .framer-lovqnv, .framer-eBClT .framer-gk5npz { flex: none; height: 1px; left: 0px; position: absolute; right: 0px; top: calc(46.15384615384618% - 1px / 2); z-index: 1; }", ".framer-eBClT .framer-v-1eodogi .framer-1eodogi { cursor: pointer; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-eBClT .framer-1eodogi { gap: 0px; } .framer-eBClT .framer-1eodogi > * { margin: 0px; margin-bottom: calc(5px / 2); margin-top: calc(5px / 2); } .framer-eBClT .framer-1eodogi > :first-child { margin-top: 0px; } .framer-eBClT .framer-1eodogi > :last-child { margin-bottom: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 41
 * @framerIntrinsicWidth 41
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"Yx1T03BKe":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"UdJC1_vBf":"tap2"}
 */
const FramerdCXjrAIvn: React.ComponentType<Props> = withCSS(Component, css, "framer-eBClT") as typeof Component;
export default FramerdCXjrAIvn;

FramerdCXjrAIvn.displayName = "Menu Nav Close Toggle";

FramerdCXjrAIvn.defaultProps = {height: 41, width: 41};

addPropertyControls(FramerdCXjrAIvn, {UdJC1_vBf: {title: "Tap 2", type: ControlType.EventHandler}} as any)

addFonts(FramerdCXjrAIvn, [])